// routes
import { PATH_AUTH } from '../routes/paths';
// utils
import apiKey from '../utils/axiosInstance';
// ----------------------------------------------------------------------
function jwtDecode(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''));
    return JSON.parse(jsonPayload);
}
// ----------------------------------------------------------------------
export const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
};
// ----------------------------------------------------------------------
export const tokenExpired = (exp, iat) => {
    // eslint-disable-next-line prefer-const
    let expiredTimer;
    const timeLeft = +`${exp - iat}000`;
    clearTimeout(expiredTimer);
    if (timeLeft > 2147483647) {
        expiredTimer = setTimeout(() => {
            setTimeout(() => {
                alert('Token expired');
                localStorage.removeItem('accessToken');
                window.location.href = PATH_AUTH.login;
            }, timeLeft - 2147483647);
        }, 2147483647);
        return;
    }
    expiredTimer = setTimeout(() => {
        alert('Token expired');
        localStorage.removeItem('accessToken');
        window.location.href = PATH_AUTH.login;
    }, timeLeft);
};
// ----------------------------------------------------------------------
export const setSession = (accessToken) => {
    if (accessToken) {
        const { exp, iat } = jwtDecode(accessToken);
        localStorage.setItem('accessToken', accessToken);
        apiKey.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        tokenExpired(exp, iat);
    }
    else {
        localStorage.removeItem('accessToken');
        delete apiKey.defaults.headers.common.Authorization;
    }
};
